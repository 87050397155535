import React from "react";
import styled from "styled-components";
import { oo } from "../element/theme";
import Typography from "@material-ui/core/Typography";

const TestimonialSection = styled.section`
  padding: ${oo.verticalSpacing}rem 0;
  text-align: center;
`;
const TestimonialItem = styled.div`
  position: relative;
  max-width: ${oo.textWidth * 0.75}rem;
  margin: 2rem auto;
  &:before {
    content: "\\201C";
    position: absolute;
    font-family: Georgia,Times New Roman,Times,serif;
    font-size: 122pt;
    top: -4.5rem;
    left: -2rem;
    z-index: 0;
    opacity: .075;
  }
`;

const heading = [
  "Focus on your game",
];
const testimonialArray = [
  // ["I'm a solo game developer, not a web developer. My time is better spent on my game.", "Jonathan Parrots — ", "Laughing Parrot Games"],
  ["There are a million things I need to do to publish my game. Let someone else handle the website.", "Maximilian Weber — ", "B-Side Games"],
];

const TestimonialQuotes = () => {
  return (
    <TestimonialSection>
      <Typography component="h2" variant="h3" gutterBottom>{heading[0]}</Typography>
      {testimonialArray.map((element, index) => (
        <TestimonialItem key={index}>
          <Typography variant="body2" gutterBottom>{element[0]}</Typography>
          <Typography variant="caption"> {element[1]}</Typography>
          <Typography variant="caption">{element[2]}</Typography>
        </TestimonialItem>
      ))}
    </TestimonialSection>
  );
};
export default TestimonialQuotes;

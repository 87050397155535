import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/element/layout/Layout";
import LayoutMain from "../components/element/layout/LayoutMain";
import SEO from "../components/element/SEO";
import Hero from "../components/home/Hero";
import routes from "../components/navigation/routes";
import TestimonialSupporters from "../components/home/TestimonialSupporters";
import TestimonialQuotes from "../components/home/TestimonialQuotes";
import HeroFeatures from "../components/home/HeroFeatures";
import FlowDiagram from "../components/home/FlowDiagram";
import CtaBannerBottom from "../components/home/CtaBannerBottom";
import PricingTable from "../components/home/PricingTable";

const seo = {
  title: "Oberion · Website Builder for Game Developers",
  description: "Generate, edit, and publish beautiful websites for your video game. Use your game page on Steam to generate the content automatically. Publish your free website within 5 minutes.",
  canonical: routes.baseUrl,
};

const Index = (props) => {
  const { data: { allFile: { edges } } } = props;
  const heroImage = edges.filter(el => el.node.childImageSharp.fluid.originalName === "discover-indie-games-OberionIO-2x.png")[0].node.childImageSharp.fluid;
  const testimonialLogos = edges.filter(
    el => el.node.childImageSharp.fluid.originalName.substring(0, 11) === "testimonial");

  return (
    <Layout showNewsletter>
      <SEO {...seo} />
      <Hero heroImage={heroImage} />
      <HeroFeatures />

      <LayoutMain>
        <TestimonialSupporters logos={testimonialLogos} />
        <FlowDiagram />
        <PricingTable />
        <TestimonialQuotes />
        <CtaBannerBottom />
      </LayoutMain>

    </Layout>
  );
};
Index.propTypes = {
  data: PropTypes.object.isRequired,
};
export const query = graphql`
{
  allFile(
    filter: {
      extension: {regex: "/(jpg)|(png)|(jpeg)/"},
      sourceInstanceName: {eq: "home-visual"}
    },
    sort: {fields: [childImageSharp___fluid___originalName], order: ASC})
  {
    edges {
      node {
      childImageSharp {
          fluid(maxWidth: 300, quality: 50) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`;
export default Index;

import React from "react";
import styled from "styled-components";
import { oo } from "../element/theme";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const HeroFeatureContainer = styled.section`
  background-color: ${oo.grey9};
  padding: ${oo.verticalSpacing}rem ${oo.verticalSpacing}rem 0 ${oo.verticalSpacing}rem;
  max-width: ${oo.contentWidth}rem;
  width: 100%;
  margin: 0 auto;
  
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;
const GridElement = styled(Grid)`
  border: 1px solid lightgray;
  border-radius: 3px;
`;
const txt = [
  ["Focus on your game", "Your priority is to make a good game. Let Oberion create a beautiful website for you so you can focus on what you do best."],
  ["Your Steam page as a template", "Oberion uses your screenshots, descriptions and gifs of your game on Steam to create a website for you."],
  ["Minimal time investment required", "Paste the link to your Steam page, Oberion creates your website within 5 minutes for free. Your website is done."],
];

const HeroFeatures = () => {
  return (
    <HeroFeatureContainer>
      <Grid container spacing={6} justify="center" alignItems="flex-start">
        {txt.map((el) => (
          <Grid item xs={12} sm={6} md={4} key={el[0]}>
            <Typography component="h2" variant="body2" gutterBottom>{el[0]}</Typography>
            <Typography component="p" variant="caption">{el[1]}</Typography>
          </Grid>
        ))}
      </Grid>
    </HeroFeatureContainer>
  );
};
export default HeroFeatures;
import React from "react";
import styled from "styled-components";
import { Button, Typography } from "@material-ui/core";
import { oo } from "../element/theme";
import routes from "../navigation/routes";
import { Link as GatsbyLink } from "gatsby";

const CtaBannerBottomSection = styled.section`
  padding: ${oo.verticalSpacing}rem 0;
  text-align: center;
  display: flex; 
  flex-flow: column nowrap;
  align-items: center;
`;
const ButtonSt = styled(Button)`
  margin: ${oo.verticalSpacing / 4}rem 0;
`;

const txt = [
  "Set up your free website now", "Pick a plan later.",
];

const CtaBannerBottom = () => {
  return (
    <CtaBannerBottomSection>
      <Typography variant="h3" gutterBottom>{txt[0]}</Typography>
      <ButtonSt component={GatsbyLink} to={routes.preview.to}
                variant="contained" color="primary">
        Get Started
      </ButtonSt>
    </CtaBannerBottomSection>
  );
};
export default CtaBannerBottom;
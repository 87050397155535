import React from "react";
import Typography from "@material-ui/core/Typography";
import styled, { keyframes } from "styled-components";
import { oo } from "../element/theme";
import { A_REL, OutboundLinkThemed } from "../navigation/ExternalLink";
import gatsbyLogo from "../../res/branded-external/etc/gatsby-logo.svg";
import oberionLogo from "../../../static/logo.OberionIO.min.svg";
import steamLogo from "../../res/branded-external/game-store/steam-black.min.svg";
import itchioLogo from "../../res/branded-external/game-store/itchio-logo-textless-black.min.svg";
import { greaterOrEqualThan } from "../element/mediaQuery";


const FlowDiagramSection = styled.section`
  text-align: center;
  padding: ${oo.verticalSpacing}rem 0;
  width: 100%;
  ${greaterOrEqualThan.sm`width: 70%;`};
  max-width: ${oo.textWidth}rem;
`;
const OrangeBox = styled(Typography)`
  display: inline;
  position: relative;
  bottom: -0.7rem;
  background-color: #fb8400;
  color: ${oo.textColorOnLight};
  border-radius: 3px;
  padding: 0.65rem 1rem;
  text-transform: uppercase;
`;
const FlowDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border: 1px solid ${oo.grey7};
  border-radius: 3px;
  padding: 0 2rem;
`;
const FlowColumnDiv = styled(FlowDiv)`
  flex-flow: column nowrap;
  align-items: center;
`;
const FlowCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  padding: 1.2rem 1.5rem;
  border-radius: 3px;
`;
const DataSourceCard = styled(FlowCard)`
  margin: 2rem 1rem;
  width: 13rem;
  ${greaterOrEqualThan.sm`width: 16rem`};
  box-shadow: ${oo.boxShadow};
`;
const BuildDiv = styled(FlowColumnDiv)`
  align-items: center;
  background-color: ${oo.oberionBlueLight};
  background-image: linear-gradient(135deg, ${oo.oberionBlue} 25%, transparent 25%, transparent 50%, ${oo.oberionBlue} 50%, ${oo.oberionBlue} 75%, transparent 75%, transparent);
`;
const SmallLogo = styled.img`
  height: 1.2em;
`;
/* --- Animate vertical line --- */
const lineAnimation = keyframes({
  to: { strokeDashoffset: 10 },
});
// noinspection CssInvalidPropertyValue
const PathSt = styled.path`
  stroke: ${oo.oberionBlueDark};
  stroke-width: 3;
  stroke-linecap: round;
  stroke-dasharray: 0.5 10;
  animation: ${lineAnimation} 400ms linear infinite;
`;
const VerticalLine = () => (
  <svg width="20"
       height="30"
       viewBox="0 0 20 30"
       css={{ margin: `0 auto`, display: `block` }}>
    <PathSt d="M10 40 L10 -10" />
  </svg>
);

const txt = [
  "How Oberion works",
  "Use store pages as a template",
  "Data Sources",
  "Build",
  "Deploy",
];
const dataSources = [
  ["Store Pages", "\xa0Steam", steamLogo, "\xa0itch.io (coming soon)", itchioLogo],
  ["Markdown", "Blog posts, news, documentation, events, etc."],
];
const buildTxt = ["powered by", "GatsbyJS\xa0", "Your Website 🕹️"];
// const buildTxt = ["powered by", "GatsbyJS\xa0", "HTML · CSS · JavaScript"];
const deployTxt = ["Web Hosting"];
const separator = ",\xa0";
const comingSoon = "\xa0(coming soon)";
const hoster1 = [
  ["GitHub Pages", "https://pages.github.com/", null, comingSoon],
  // ["AWS Amplify", "https://www.gatsbyjs.org/docs/deploying-to-aws-amplify/", separator],
  // ["Google Cloud & Firebase", "https://www.gatsbyjs.org/docs/deploying-to-firebase/", separator],
  // ["Gatsby Cloud", "https://www.gatsbyjs.org/docs/deploying-to-gatsby-cloud/", null],
];
const hoster2 = [
  ["Netlify", "https://www.netlify.com/", separator, comingSoon],
  ["Gatsby Cloud", "https://www.gatsbyjs.com/cloud/", null, comingSoon],
];

const FlowDiagram = () => {
  return (
    <FlowDiagramSection>
      <Typography component="h2" variant="h4" paragraph>{txt[0]}</Typography>
      <Typography component="h2" variant="body1"
                  style={{ marginBottom: "3rem" }}>{txt[1]}</Typography>

      {/* --- Data Sources --- */}
      <OrangeBox component="h3" variant="caption">{txt[2]}</OrangeBox>
      <FlowDiv>
        <DataSourceCard>
          <Typography variant="subtitle2">{dataSources[0][0]}</Typography>
          <div>
            <SmallLogo src={dataSources[0][2]} alt={dataSources[0][1]} />
            <Typography variant="caption">{dataSources[0][1]}</Typography>
          </div>
          <div>
            <SmallLogo src={dataSources[0][4]} alt={dataSources[0][3]} />
            <Typography variant="caption">{dataSources[0][3]}</Typography>
          </div>
        </DataSourceCard>
        {/*<DataSourceCard>*/}
        {/*  <Typography variant="subtitle2">{dataSources[1][0]}</Typography>*/}
        {/*  <Typography variant="caption">{dataSources[1][1]}</Typography>*/}
        {/*</DataSourceCard>*/}
      </FlowDiv>

      {/* --- Build --- */}
      <VerticalLine />

      <OrangeBox component="h3" variant="caption">{txt[3]}</OrangeBox>
      <BuildDiv>
        <VerticalLine />
        <FlowCard>
          <img src={oberionLogo} alt="Oberion" style={{ height: "3.5rem" }} />
          <Typography variant="caption"
                      style={{ color: oo.grey3, fontSize: "0.8rem", padding: "0.2rem 0" }}>
            {buildTxt[0]}
          </Typography>
          <div>
            <OutboundLinkThemed variant="caption" lightTheme
                                caption="GatsbyJS" href="https://www.gatsbyjs.org/"
                                rel={A_REL.ENDORSE} />
            {"\xa0"}
            <SmallLogo src={gatsbyLogo} alt="GatsbyJS" />
          </div>
        </FlowCard>
        <VerticalLine />
        <FlowCard><Typography variant="body1">{buildTxt[2]}</Typography></FlowCard>
        <VerticalLine />
      </BuildDiv>

      {/* --- Deploy --- */}
      <VerticalLine />
      <OrangeBox component="h3" variant="caption">{txt[4]}</OrangeBox>
      <FlowColumnDiv>
        <Typography component="p" variant="body2" style={{ marginTop: "1.5rem" }}>
          {deployTxt[0]}
        </Typography>
        <OutboundLinkThemed variant="caption" lightTheme
                            caption={hoster1[0][0]} href={hoster1[0][1]} rel={A_REL.ENDORSE} />
        <div style={{ marginBottom: "1.5rem" }}>
          {hoster2.map(el => (
            <span key={el[0]}>
              <OutboundLinkThemed variant="caption" lightTheme
                                  caption={el[0]} href={el[1]} rel={A_REL.ENDORSE} />
              {el[3]}
              {el[2]}
            </span>
          ))}
        </div>
      </FlowColumnDiv>
    </FlowDiagramSection>
  );
};
export default FlowDiagram;
import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { muiDarkTheme } from "../navigation/footer/muiDarkTheme";
import { Button, TextField } from "@material-ui/core";
import routes from "../navigation/routes";
import styled from "styled-components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { greaterOrEqualThan } from "../element/mediaQuery";

const HeroFormContainer = styled.form`
  margin-top: 2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;
const TextFieldSt = styled(TextField)`
  width: 19rem;
  ${greaterOrEqualThan.sm`width: 35rem;`};
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`margin-left: 1rem;`;
const txt = ["See Preview", "https://store.steampowered.com/app/...", "Copy & paste the Steam URL of your game"];


const HeroForm = () => {
  return (
    <HeroFormContainer noValidate autoComplete="off"
                       action={routes.preview.to}
                       method="get">
      <MuiThemeProvider theme={muiDarkTheme}>
        <TextFieldSt id="url"
                     type="url"
                     label={txt[1]}
                     variant="outlined"
                     helperText={txt[2]}
                     color="secondary"
                     name="url"
        />
      </MuiThemeProvider>
      <div style={{ margin: "0 auto" }}>
        <Button color="secondary" variant="contained"
                size="large" style={{ margin: "1rem 0" }}
                type="submit"
                value="submit"
        >
          {txt[0]}
          <StyledArrowForwardIcon />
        </Button>
      </div>
    </HeroFormContainer>
  );
};
export default HeroForm;
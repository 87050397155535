import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import GatsbyImage from "gatsby-image";
import { A_REL, LINK_TO, OutboundLinkNoStyle } from "../navigation/ExternalLink";
import { oo } from "../element/theme";

const logoData = [
  {
    originalName: "EXIST Startup Grant",
    alt: "Recipient of EXIST Business Startup Grant from Feb 2018 to Jan 2019",
    link: LINK_TO.exist,
    imageStyle: {
      width: "6.5rem",
    },
  }, {
    originalName: "Technische Universitaet Braunschweig",
    alt: "Academic startup from the Technische Universität Braunschweig",
    link: LINK_TO.tubs,
    imageStyle: {
      width: "8.5rem",
    },
  }, {
    originalName: "borek.digital",
    alt: "Accelerated by borek.digital batch #5",
    link: LINK_TO.borek,
    imageStyle: {
      width: "8.8rem",
    },
  }, {
    originalName: "Telekom Open Cloud",
    alt: "Runs on Telekom Open Cloud",
    link: LINK_TO.telekom,
    imageStyle: {
      width: "6.5rem",
    },
  }, {
    originalName: "Google Cloud for Startups",
    alt: "Runs on Google Cloud for Startups",
    link: LINK_TO.googleCloud,
    imageStyle: {
      width: "10.5rem",
    },
  },
];

const TestimonialSection = styled.section`
  padding-bottom: ${oo.verticalSpacing}rem;
  max-width: ${oo.contentWidth}rem;
`;
const LogoDiv = styled.div`
  height: 5rem;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logos = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;
const SupportTypo = styled.div`
  color: ${oo.grey3};
  text-align: center;
  font-size: 0.75rem;
`;

const TestimonialSupporters = ({ logos }) => {
  const logoArray = logos.map((el, index) => logos[index].node.childImageSharp.fluid);

  return (
    <TestimonialSection>
      <SupportTypo component="span" variant="caption">Supported by</SupportTypo>
      <Logos>
        {logoArray.map((el, index) => (
          <LogoDiv key={logoData[index].originalName}>
            <OutboundLinkNoStyle href={logoData[index].link} rel={A_REL.ENDORSE}>
              <GatsbyImage fluid={el}
                           title={logoData[index].alt}
                           alt={logoData[index].alt}
                           style={logoData[index].imageStyle} />
            </OutboundLinkNoStyle>
          </LogoDiv>
        ))}
      </Logos>
    </TestimonialSection>
  );
};
TestimonialSupporters.propTypes = {
  logos: PropTypes.array.isRequired,
};
export default TestimonialSupporters;
import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import wave from "./visual-svg/background-wave-OberionIO.min.svg";
import stars from "./visual-svg/background-stars-OberionIO.min.svg";
import ooTheme, { oo } from "../element/theme";
import HeroForm from "./HeroForm";

const BackgroundDiv = styled.div`
  color: ${ooTheme.palette.primary.contrastText};
  background-color: #00002c;
  background-image: url(${wave}), url(${stars});
  background-size: contain, auto;
  background-position: bottom center;
  background-repeat: no-repeat, repeat;
  min-height: 40rem;
  padding: 0 0.5rem;
  text-align: left;
  border-bottom: solid 1rem ${oo.oberionBlueBackground};
  
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeroLeft = styled.div`
  max-width: 36rem;
  margin: 3rem 0.5rem 1rem 0.5rem;
`;
const gatsbyImageStyle = {
  margin: "4rem 1rem",
  minWidth: "18rem",
  maxWidth: "19rem",
};
const heading = "The Website Builder made for Game Developers";
const subheading = ["Create the website for your game · Publish within 5 minutes"];


const Hero = ({ heroImage }) => {
  return (
    <BackgroundDiv>
      <Grid container spacing={0} justify="center">
        <HeroLeft>
          <Typography component="h1" variant="h1" gutterBottom>{heading}</Typography>
          <Typography component="h2" variant="subtitle1">{subheading[0]}</Typography>
          <Typography component="h2" variant="subtitle1">{subheading[1]}</Typography>
          <HeroForm />
        </HeroLeft>
        <GatsbyImage fluid={heroImage} alt={heading} style={gatsbyImageStyle} />
      </Grid>
    </BackgroundDiv>
  );
};

Hero.propTypes = {
  heroImage: PropTypes.object.isRequired,
};

export default Hero;
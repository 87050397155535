import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ooTheme, { oo } from "../element/theme";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link as GatsbyLink } from "gatsby";
import routes from "../navigation/routes";

const TableHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
const OrangeBox = styled(Typography)`
  position: relative;
  top: -0.5rem;
  //background-color: ${oo.oberionBlue};
  background-color: #fb8400;
  color: ${oo.textColorOnDark};
  border-radius: 3px;
  padding: 0.5rem 1rem;
  width: 11rem;
  height: 2.5rem;
  margin: 0 auto;
`;
const PushToBottom = styled.div`
  height: 2.5rem;
`;

const useStyles = makeStyles({
  section: {
    textAlign: "center",
    padding: oo.verticalSpacing + "rem 0",
    width: oo.textWidth + "rem",
    [ooTheme.breakpoints.down("xs")]: {
      width: "20rem",
    },
  },
  root: {
    marginTop: oo.verticalSpacing / 2 + "rem",
    backgroundColor: oo.grey9,
    width: "100%",
    overflowX: "auto", // if *any* parent has no defined width, overflow scroll won't occur
  },
  table: {
    width: "100%",
    // maxWidth: "44rem",
  },
});

const GetStartedButton = (
  <Button component={GatsbyLink} to={routes.preview.to}
          variant="outlined" color="primary">
    Get Started
  </Button>);
const ComingSoonButton = (
  <Button disabled
          variant="outlined" color="primary">
    Coming Soon
  </Button>);

// const headers = [
//   ["Free", "$0", "month"],
//   ["Indie", "$9", "month", "Skip the Grind"],
// ];
// function createData(feature, Free, indie) {
//   return { feature, Free, indie };
// }
// const rows = [
//   createData("Generate from your Steam page", "Unlimited", "Unlimited"),
//   createData("Sites", "Unlimited", "Unlimited"),
//   createData("Source Code", "Free", "Free"),
//   createData("Hosting", "Host Anywhere", "Host Anywhere"),
//   createData("One-Click-Deployment", "✔", "✔"),
//   createData("Automatic Updates", "―", "✔"),
//   createData("Remove Branding", "―", "✔"),
//   createData("Email Lists", "―", "✔"),
//   createData("Blogs", "―", "✔"),
//   createData("Humble Widget", "―", "✔"),
//   createData("", GetStartedButton, ComingSoonButton),
// ];
const headers = [
  ["Free", "$0", "month"],
];
function createData(feature, Free) {
  return { feature, Free };
}
const rows = [
  createData("Generate from your Steam page", "Unlimited"),
  createData("Sites", "Unlimited"),
  createData("Source Code", "Free"),
  createData("Hosting", "Host Anywhere"),
  createData("One-Click-Deployment", "✔",),
  createData("", GetStartedButton, ComingSoonButton),
];

const pricingTxt = [
  "It's free", "You focus on game development. Oberion handles your website.",
];

const createTableHeader = (txt) => {
  let emphasis = <PushToBottom />;
  // if (txt[3]) {
    // emphasis = ComingSoonButton;
    // emphasis = (
    //   <OrangeBox component="span" variant="body2">{txt[3]}</OrangeBox>
    // );
  // }
  return (
    <TableHeader>
      {/*{emphasis}*/}
      <Typography component="span" variant="body2">{txt[0]}</Typography>
      <Typography component="span" variant="h4">{txt[1]}</Typography>
      <Typography component="span" variant="caption">{txt[2]}</Typography>
    </TableHeader>
  );
};

export default function PricingTable() {
  const classes = useStyles();
  return (
    <section id="pricing" className={classes.section}>
      <Typography component="h2" variant="h3" gutterBottom>{pricingTxt[0]}</Typography>
      <Typography component="p" variant="h6">{pricingTxt[1]}</Typography>
      <TableContainer component={"div"} className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {headers.map(el => (
                <TableCell align="center" key={el[0]}>{createTableHeader(el)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.feature}>
                <TableCell component="th" scope="row">{row.feature}</TableCell>
                <TableCell align="center">{row.Free}</TableCell>
                <TableCell align="center">{row.indie}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}